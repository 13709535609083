import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Seamless process from start to finish, very efficient service. Would recommend!</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Atila Esposa </span> - COO, Miriad Studios, Inc.</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>I can think of nothing but superlatives for My24Website, who revamped my static website into a cutting-edge platform for selling insurance. Easy to communicate with and extremely cost effective. A truly happy collaboration.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Anthony Joseph </span> - GM, Monastir Insurance</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Thanks for all your work and ideas. It really brought my website to the next level.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Donald Collins </span> - Pan Continental</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>My people at Flavours of India love My24Website, you guys should get a Pulitzer for web publishing!</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Anna Gurke </span> - Flavours of India</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Highly professional, dedicated, motivated team!</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Olya Kuznetsova</span> - Redia Consulting</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Amazing service from brilliant website professionals. I got everything I looked for and more - including photography, branding and SEO!</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Orlin Calderon </span> - Avelanche AI</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>It has been a pleasure to work with My24Website to get the new 2Pac Music Festival online. Tight deadlines were met, while still responding to new ideas and requests.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Anita Hoile </span> - Brahms Media</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>It was inspiring journey from fledgling business to a bespoke contender, supported all the way by My24Website. Keep going!</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Camila Ewing </span> - Felicity Herbal</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            
                            <TabList className="testimonial-thumb-wrapper">
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-1.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-2.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-3.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-4.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-5.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-6.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-7.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-8.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                            </TabList>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;