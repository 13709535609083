import React ,{ Component }from "react";
import { FiPhoneOutgoing, FiRotateCw, FiTrello } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiPhoneOutgoing />,
        title: '1. Requirements',
        description: 'Call and discuss website goals and expectations. Select content structure and website layout.'
    },
    {
        icon: <FiTrello />,
        title: '2. Delivery',
        description: 'In 24 hours your website will be up and running. Any modifications will take another 24 hours.'
    },
    {
        icon: <FiRotateCw />,
        title: '3. Maintenance',
        description: 'As your business changes and grows, so does your website. Your subscription covers all updates.'
    },
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href="#pricing">
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
