import React, { Component } from "react";
import tacts from '../../../public/assets/images/about/tacts.png';

class Footer extends Component{
    render(){
        return(
            <React.Fragment>
                <footer id="contact" className="footer-area">
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0">
                            <div className="col-lg-6">
                                <div className="footer-left">
                                    <div className="inner">
                                        <span>Up Tomorrow</span>
                                        <h2>Start Today</h2>
                                        <a className="rn-button-style--2" href={"https://secure.2checkout.com/checkout/buy?merchant=1283829&tpl=default&prod=2&qty=1"}>
                                            <span>Subscribe @ $95/mo</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer-right" data-black-overlay="6">
                                    <div className="row">
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12">
                                            <div className="footer-link">
                                                <h4>Quick Links</h4>
                                                <ul className="ft-link">
                                                    <li><a href="#getting-started">Getting Started</a></li>
                                                    <li><a href="#portfolio">Portfolio</a></li>
                                                    <li><a href="#pricing">Pricing</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4>Say Hello</h4>
                                                <img src={tacts} alt="" />
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}

                                        <div className="col-lg-12">
                                            <div className="copyright-text">
                                                <p>Copyright © 2020 My24Website. All Rights Reserved.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;