import React, { Component , Fragment } from "react";
import { Parallax } from "react-parallax";
import Scrollspy from 'react-scrollspy';
import ScrollToTop from 'react-scroll-up';
// import { FiChevronUp } from "react-icons/fi";
import { FiChevronUp , FiX , FiMenu } from "react-icons/fi";
import Footer from "../component/footer/Footer";
import TabThree from "../elements/tab/TabThree";

import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import BrandTwo from "../elements/BrandTwo";
import ServiceList from "../elements/service/ServiceList";
import Helmet from "../component/common/Helmet";

const SlideList = [
    {
        textPosition: 'text-center',
        category: 'Completely Managed',
        title: 'In 24 Hours',
        description: 'Web design, updates and hosting - all in one subscription.',
        buttonText: 'Getting Started',
        buttonLink: '#getting-started'
    }
]
const sliderImage =
  "/assets/images/bg/paralax/1.jpg";

const image1 =
  "/assets/images/bg/paralax/2.jpg";

const image2 =
  "/assets/images/bg/paralax/3.jpg";

const image3 =
  "/assets/images/bg/paralax/4.jpg";


const image6 =
  "/assets/images/bg/paralax/5.jpg";

const image7 =
  "/assets/images/bg/paralax/6.jpg";

const image8 =
  "/assets/images/bg/paralax/7.jpg";

class Paralax extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    stickyHeader () {}
    render(){
        // const PostList = BlogContent.slice(0 , 3);
        // const ServiceContent = ServiceList.slice(0 , 3);

        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 50) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }

        // <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
        return(
            <Fragment> 
                <Helmet pageTitle="My24Website | Completely Managed in 24 Hours" />
                
                {/* Start Header Area  */}
                <header id="header-wrapper" className="header-area formobile-menu header--fixed default-color">
                    <div className="header-wrapper" >
                        <div className="header-left">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-1" src="/assets/images/logo/logo-light.png" alt="Logo Images"/>
                                    <img className="logo-2" src="/assets/images/logo/logo-all-dark.png" alt="Logo Images"/>
                                </a>
                            </div>
                        </div>
                        <div className="header-right">
                            <nav className="mainmenunav d-lg-block">
                                <Scrollspy className="mainmenu" items={['welcome','getting-started','portfolio','pricing','contact']} currentClassName="is-current" offset={-400}>
                                    <li><a href="#welcome">Welcome</a></li>
                                    <li><a href="#getting-started">Getting Started</a></li>
                                    <li><a href="#portfolio">Portfolio</a></li>
                                    <li><a href="#pricing">Pricing</a></li>
                                    <li><a href="#contact">Contact</a></li>
                                </Scrollspy>
                            </nav>
                            {/* Start Humberger Menu  */}
                            <div className="humberger-menu d-block d-lg-none pl--20">
                                <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                            </div>
                            {/* End Humberger Menu  */}
                            <div className="close-menu d-block d-lg-none">
                                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                            </div>
                        </div>
                    </div>
                </header>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div id="welcome" className="slider-wrapper">
                    {/* Start Single Slide */}
                    <Parallax bgImage={sliderImage} strength={700}>
                        {SlideList.map((value , index) => (
                            <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className={`inner ${value.textPosition}`}>
                                                {value.category ? <h2 className="title theme-gradient">{value.category}</h2> : ''}
                                                {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                                {value.description ? <p className="description">{value.description}</p> : ''}
                                                {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Parallax>
                </div>
                {/* End Slider Area   */}
                
                {/* Start Service Area  */}
                <Parallax className="rn-paralax-service" bgImage={image1} strength={1000}>
                    <div id="getting-started" className="service-area ptb--120">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12">
                                    <div className="section-title service-style--2 text-center mb--30 mb_sm--0">
                                        <h2>Getting Started</h2>
                                    </div>
                                </div>
                            </div>
                            <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12" />
                        </div>
                    </div>
                </Parallax>
                {/* End Service Area  */}
                
                {/* Start CounterUp Area */}
                <Parallax className="rn-counterup-area rn-paralax-counterup" bgImage={image6} strength={1000} >
                    <div className="counterup-area ptb--120">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title service-style--2 text-center mb--30 mb_sm--0">
                                        {/* <h2>Success in figures</h2> */}
                                    </div>
                                </div>
                            </div>
                            <CounterOne />
                        </div>
                    </div>
                </Parallax>
                {/* End CounterUp Area */}

                {/* Start Designer Portfolio  */}
                <Parallax className="rn-paralax-portfolio"  bgImage={image2} strength={500} >
                    <div id="portfolio" className="portfolio-area ptb--120" data-black-overlay="6">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title service-style--2 text-center mb--30 mb_sm--0">
                                        <h2>Portfolio</h2>
                                        <TabThree column="col-lg-4 col-md-6 col-sm-6 col-12" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Parallax>
                {/* End Designer Portfolio  */}
                
                {/* Start Testimonial Area */}
                <Parallax className="rn-testimonial-area rn-paralax-testimonial  rn-testimonial-light" bgImage={image7} strength={500} data-black-overlay="5">
                    <div className="testimonial-area ptb--120" data-black-overlay="6">
                        <div className="container">
                            <Testimonial />
                        </div>
                    </div>
                </Parallax>
                {/* End Testimonial Area */}

                {/* Start Brand Area */}
                <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={image8} strength={1000}>
                    <div className="brand-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <BrandTwo />
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </Parallax>
                {/* End Brand Area */}

                {/* Start Blog Area */}
                <Parallax className="rn-blog-area rn-testimonial-light" bgImage={image3} strength={700} data-black-overlay="7">
                    <div id="pricing" className="blog-area ptb--120" data-black-overlay="6">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title service-style--2 text-center mb--30 mb_sm--0">
                                        <h2>Pricing</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <div className="blog blog-style--1 mb--0 mt--40">
                                        <div className="thumbnail">
                                            <a href={"https://secure.2checkout.com/checkout/buy?merchant=1283829&tpl=default&prod=2&qty=1"}>
                                                <img className="w-100" src={`/assets/images/blog/1.jpg`} alt="My Startup Website"/>
                                            </a>
                                        </div>
                                        <div className="content">
                                            
                                            <h4 className="title"><a href={"https://secure.2checkout.com/checkout/buy?merchant=1283829&tpl=default&prod=2&qty=1"}>My Startup Website</a></h4>
                                            <ul className="features">
                                                <li className="included">Web Design</li>
                                                <li className="included">Web Hosting</li>
                                                <li className="included">Domain Registration</li>
                                                <li className="included">Email</li>
                                                <li className="included">Search Engine Optimization</li>
                                                <li className="included">Google Analytics</li>
                                                <li className="included">Updates and Maintenance</li>
                                                <li className="included">Embeded Services</li>
                                                <li className="not-included">Contact Forms</li>
                                                <li className="not-included">User Login</li>
                                                <li className="not-included">Dynamic Content</li>
                                            </ul>
                                            <p className="blogtype">$95/month</p>
                                            <div className="blog-btn">
                                                <a className="rn-btn text-white" href={"https://secure.2checkout.com/checkout/buy?merchant=1283829&tpl=default&prod=2&qty=1"}>Subscribe</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <div className="blog blog-style--1 mb--0 mt--40">
                                        <div className="thumbnail">
                                            <a href={"https://secure.2checkout.com/checkout/buy?merchant=1283829&tpl=default&prod=4&qty=1"}>
                                                <img className="w-100" src={`/assets/images/blog/2.jpg`} alt="My Premium Website"/>
                                            </a>
                                        </div>
                                        <div className="content">
                                            <h4 className="title"><a href={"https://secure.2checkout.com/checkout/buy?merchant=1283829&tpl=default&prod=4&qty=1"}>My Premium Website</a></h4>
                                            <ul className="features">
                                                <li className="included">Web Design</li>
                                                <li className="included">Web Hosting</li>
                                                <li className="included">Domain Registration</li>
                                                <li className="included">Email</li>
                                                <li className="included">Search Engine Optimization</li>
                                                <li className="included">Google Analytics</li>
                                                <li className="included">Updates and Maintenance</li>
                                                <li className="included">Embeded Services</li>
                                                <li className="included">Contact Forms</li>
                                                <li className="included">User Login</li>
                                                <li className="not-included">Dynamic Content</li>
                                            </ul>
                                            <p className="blogtype">$225/month</p>
                                            <div className="blog-btn">
                                                <a className="rn-btn text-white" href={"https://secure.2checkout.com/checkout/buy?merchant=1283829&tpl=default&prod=4&qty=1"}>Subscribe</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <div className="blog blog-style--1 mb--0 mt--40">
                                        <div className="thumbnail">
                                            <a href={"https://secure.2checkout.com/checkout/buy?merchant=1283829&tpl=default&prod=6&qty=1"}>
                                                <img className="w-100" src={`/assets/images/blog/3.jpg`} alt="My Ultimate Website"/>
                                            </a>
                                        </div>
                                        <div className="content">
                                            <h4 className="title"><a href={"https://secure.2checkout.com/checkout/buy?merchant=1283829&tpl=default&prod=6&qty=1"}>My Ultimate Website</a></h4>
                                            <ul className="features">
                                                <li className="included">Web Design</li>
                                                <li className="included">Web Hosting</li>
                                                <li className="included">Domain Registration</li>
                                                <li className="included">Email</li>
                                                <li className="included">Search Engine Optimization</li>
                                                <li className="included">Google Analytics</li>
                                                <li className="included">Updates and Maintenance</li>
                                                <li className="included">Embeded Services</li>
                                                <li className="included">Contact Forms</li>
                                                <li className="included">User Login</li>
                                                <li className="included">Dynamic Content</li>
                                            </ul>
                                            <p className="blogtype">$375/month</p>
                                            <div className="blog-btn">
                                                <a className="rn-btn text-white" href={"https://secure.2checkout.com/checkout/buy?merchant=1283829&tpl=default&prod=6&qty=1"}>Subscribe</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    
                </Parallax>
                {/* End Blog Area */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </Fragment>
        )
    }
}
export default Paralax;